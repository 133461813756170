import React from 'react'
import './skills.css';


const Frontend = () => {
  return (
    <div className='skills__content'>
        <h3 className='skills__title'>Frontend Technologies</h3>
        <div className='skills__box'>
            <div className='skills__group'>
                <div className='skills__data'>
                <i class='bx bxl-html5' ></i>
                    <div>
                        <h3 className='skills__name'>HTML</h3>
                        <span className='skills_level'>Intermediate</span>
                    </div>
                </div>

                <div className='skills__data'>
                <i class='bx bxl-css3' ></i>
                    <div>
                        <h3 className='skills__name'>CSS</h3>
                        <span className='skills_level'>Intermediate</span>
                    </div>
                </div>

                <div className='skills__data'>
                <i class='bx bxl-javascript' ></i>
                    <div>
                        <h3 className='skills__name'>JavaScript</h3>
                        <span className='skills_level'>Intermediate</span>
                    </div>
                </div>
            </div>
            <div className='skills__group'>
                <div className='skills__data'>
                <i class='bx bxl-react' ></i>
                    <div>
                        <h3 className='skills__name'>React</h3>
                        <span className='skills_level'>Basic</span>
                    </div>
                </div>

                <div className='skills__data'>
                <i class='bx bxl-wordpress' ></i>
                    <div>
                        <h3 className='skills__name'>Wordpress</h3>
                        <span className='skills_level'>Basic</span>
                    </div>
                </div>

                <div className='skills__data'>
                <i class='bx bxl-adobe' ></i>
                    <div>
                        <h3 className='skills__name'>Adobe XD</h3>
                        <span className='skills_level'>Basic</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

  )
}

export default Frontend