import React from "react";
import "./qualification.css";

const Qualification = () => {
  return (
    <section className="qualification__secton" id="qualification">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My Personal Journey</span>

      <div className="qualification__container container">
        {/* <div className="qualification__tabs">
          <div>
            <i class="uil uil-graduation-cap"></i>Education
          </div>

          <div>
            <i class="uil uil-suitcase"></i>Experience
          </div>
        </div> */}

        <div className="qualification__sections">
          <div className="qualification__content">
            {/* copy from here  */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Software Engineer</h3>
                <span className="qualification__subtitle">Capgemini India</span>
                <div className="qualification__calender">
                  <i class="uil uil-calendar-alt"></i>{" "}June 2021-Nov 2022
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* till here */}

            {/* --- */}

            {/* copy from here  */}
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Full Stack Intern</h3>
                <span className="qualification__subtitle">Capgemini India</span>
                <div className="qualification__calender">
                  <i class="uil uil-calendar-alt"></i>{" "}Mar 2021-June 2021
                </div>
              </div>
            </div>
            {/* till here */}

            {/* copy from here  */}
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Computer Engineering</h3>
                <span className="qualification__subtitle">Pune University</span>
                <div className="qualification__calender">
                  <i class="uil uil-calendar-alt"></i>{" "}2016-2020
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            {/* till here */}

            {/* --- */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
