const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
        <a
          href="https://www.linkedin.com/in/sahilgogave/"
          className="info__link"
          target="_blank"
        >
          <i class="bx bxl-linkedin about__icon"></i>
          <h3 className="about__title">Linkedin</h3>
        </a>
        <span className="about__subtitle">Open for hire</span>
      </div>

      <div className="about__box">
        <a
          href="https://github.com/sahilgogave"
          className="info__link"
          target="_blank"
        >
          <i class="bx bxl-github about__icon"></i>
          <h3 className="about__title">Github</h3>
        </a>
        <span className="about__subtitle">My code diary</span>
      </div>

      <div className="about__box">
        <a
          href="https://www.behance.net/sahilgogave"
          className="info__link"
          target="_blank"
        >
          <i class="bx bxl-behance about__icon"></i>
          <h3 className="about__title">Behance</h3>
        </a>
        <span className="about__subtitle">Under Construction</span>
      </div>
    </div>
  );
};

export default Info;
