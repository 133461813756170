import React from "react";

const Socials = () => {

  const ChangeThemeToDark=()=>{
    document.documentElement.style.setProperty('--body-color','#222222');
    document.documentElement.style.setProperty('--container-color','#212529');
    document.documentElement.style.setProperty('--title-color','#ddf0ff');
    document.documentElement.style.setProperty('--title-color-dark','#F8F9FA');
    document.documentElement.style.setProperty('--text-color','#E9ECEF');
    document.documentElement.style.setProperty('--light-mode-shadow','#d6dee4');
  }
  // --title-color: hsl(var(--hue), var(--sat), 20%);
  // --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  // --text-color: hsl(var(--hue), var(--sat), 46%);
  // --body-color: hsl(var(--hue), var(--sat), 98%);
  // --container-color: #fff; 
  // --light-mode-shadow:#6C757D;
  const ChangeThemeToLight=()=>{
    document.documentElement.style.setProperty('--body-color','hsl(var(--hue), var(--sat), 98%)');
    document.documentElement.style.setProperty('--container-color','#fff');
    document.documentElement.style.setProperty('--title-color','hsl(var(--hue), var(--sat), 20%)');
    document.documentElement.style.setProperty('--title-color-dark','hsl(var(--hue), var(--sat), 0%)');
    document.documentElement.style.setProperty('--text-color','hsl(var(--hue), var(--sat), 46%)');
    document.documentElement.style.setProperty('--light-mode-shadow','#6C757D');

  }


  return (
    <div className="home__social">
      {/* <a href="https://github.com/sahilgogave" className="home__social-icon" target="_blank">
        <i class="uil uil-github"></i>
      </a> */}
      <a className="home__social-icon"  onClick={ChangeThemeToLight}>
        <i class="uil uil-sun"></i>
      </a>
      <a className="home__social-icon" onClick={ChangeThemeToDark}>
        <i class="uil uil-moon"></i>
      </a>
    </div>
  );
};

export default Socials;
