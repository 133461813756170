const Data=()=>{
    return(
        <div className="home__data">
            <h1 className="home__title ">Sahil Gogave</h1>
            <h3 className="home__subtitle glitch">Hello, World!</h3>
            {/* <p className="home__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl suscipit adipiscing bibendum est.
            </p> */}
            <p className="home__description">
            I’m a front-end and software developer based in Pune, India.
            </p>
            <a href="#contact" className="button button--flex">
                Say Hello  👋
            </a>
        </div>
        
    );
}

export default Data;