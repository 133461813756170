import React from "react";
import PortfolioData from "./PortfolioData";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <section className="portfolio section" id="portfolio">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">My work</span>

      <div className="portfolio__container container grid">
        {PortfolioData.map(({ id, image, title,alt, desc, github, demo }) => {
          return (
            <article className="portfolio__item">
              <h3>{title}</h3>
              <div className="portfolio__item-image ">
                <img src={image} alt={alt} className="project__img" />
              </div>
              {/* <h3>{title}</h3> */}
              {/* <span className="portfolio__subtitle">{desc}</span> */}
              <div className="portfolio__item-cta">
                <a
                  href={github}
                  className="button button--flex"
                  target="_blank"
                >
                  Github
                </a>
                <a
                  href={demo}
                  className="button button--flex btn-primary"
                  target="_blank"
                >
                  Demo
                </a>
              </div>
            </article>
          );
        })}
      
      </div>
      <span className="section__subtitle view__more"><a
                  href="https://sahil-projects.netlify.app/"
                  className="button button--flex btn-primary"
                  target="_blank"
                >
                  View More
                </a></span>
    </section>
  );
};

export default Portfolio;
