import React from "react";
import "./skills.css";

const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend Technologies</h3>

      <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class="bx bxl-python"></i>
                    <div>
                    <h3 className="skills__name">Python</h3>
                    <span className="skills_level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class="bx bxl-c-plus-plus"></i>
                    <div>
                    <h3 className="skills__name">C++</h3>
                    <span className="skills_level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class="bx bxl-nodejs"></i>
                    <div>
                    <h3 className="skills__name">Node Js</h3>
                    <span className="skills_level">Basic</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                    <i class="bx bxl-django"></i>
                    <div>
                    <h3 className="skills__name">Django</h3>
                    <span className="skills_level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class="bx bxs-data"></i>
                    <div>
                    <h3 className="skills__name">MySQL</h3>
                    <span className="skills_level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class="bx bxl-firebase"></i>
                    <div>
                    <h3 className="skills__name">Firebase</h3>
                    <span className="skills_level">Basic</span>
                    </div>
                </div>
            </div>
            
      </div>
    </div>


  );
};

export default Backend;
