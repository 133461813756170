import "./App.css";

import Header from "./components/header/Header";
import Home from "./components/home/Home";
import AboutMe from "./components/about/AboutMe";
import Skills from "./components/skills/Skills";
import Qualification from "./components/Qualification/Qualification";
import Portfolio from "./components/portfolio/Portfolio";
import ContactMe from "./components/contact/ContactMe";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";


function App() {
  return (
    <>
      <Header />

      <main className="main">
        <Home />
        <Skills />
        <Portfolio />
        <AboutMe />
        <Qualification />
        <ContactMe />
      </main>
      <Footer />
      <ScrollUp />
    </>
  );
}

export default App;
