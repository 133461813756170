import { useState } from "react";
import "./header.css";

const Header = () => {
  // changer background header
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-scroll");
  });

  /* toggle menu */
  const [menuToggle, setMenuToggle] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");
  return (
    <header className="header">
      <nav className="nav container">
        <a href="#home" className="nav__logo glitch">
          Sahil
        </a>
        <div className={menuToggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a
                href="#home"
                onClick={() => {
                  setActiveNav("#home");
                  setMenuToggle(!menuToggle);
                }}
                className={
                  activeNav === "#home" ? "nav__link active-link" : "nav__link"
                }
              >
                <i class="uil uil-home nav__icon"></i>
                Home
              </a>
            </li>

            <li>
              <a
                href="#skills"
                onClick={() => {
                  setActiveNav("#skills");
                  setMenuToggle(!menuToggle);
                }}
                className={
                  activeNav === "#skills" ? "nav__link active-link" : "nav__link"
                }
              >
                <i class="uil uil-brackets-curly nav__icon"></i>
                Skills
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                onClick={() => {
                  setActiveNav("#portfolio");
                  setMenuToggle(!menuToggle);
                }}
                className={
                  activeNav === "#portfolio" ? "nav__link active-link" : "nav__link"
                }
              >
                <i class="uil uil-window nav__icon"></i>
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#about"
                onClick={() => {
                  setActiveNav("#about");
                  setMenuToggle(!menuToggle);
                }}
                className={
                  activeNav === "#about" ? "nav__link active-link" : "nav__link"
                }
              >
                <i class="uil uil-user nav__icon"></i>
                About
              </a>
            </li>
            <li>
              <a
                href="#qualification"
                onClick={() => {
                  setActiveNav("#qualification");
                  setMenuToggle(!menuToggle);
                }}
                className={
                  activeNav === "#qualification" ? "nav__link active-link" : "nav__link"
                }
              >
                <i class="uil uil-briefcase-alt nav__icon"></i>
                Qualification
              </a>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => {
                  setActiveNav("#contact");
                  setMenuToggle(!menuToggle);
                }}
                className={
                  activeNav === "#contact" ? "nav__link active-link" : "nav__link"
                }
              >
                <i class="uil uil-envelope-upload nav__icon"></i>
                Contact
              </a>
            </li>
          </ul>
          <i
            class="uil uil-times nav__close"
            onClick={() => {
              setMenuToggle(!menuToggle);
              console.log("menu toggled");
            }}
          ></i>
        </div>
        <div
          className="nav__toggle"
          onClick={() => {
            setMenuToggle(!menuToggle);
            console.log("menu toggled");
          }}
        >
          <i class="uil uil-bars nav__toggle"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
