import Placeholder from '../../assets/placeholder.jpg'
import WeatherApp from '../../assets/weather.png'
import MemoryGame from '../../assets/memorygame.png'
import NoteIt from '../../assets/noteit.png'
import StupidBlog from '../../assets/stupidblog.png'
import Conference from '../../assets/conference.png'
import TODO from '../../assets/todo.png'
import Farewellcard from '../../assets/farewellcards.png'

const PortfolioData =[
    {
        id:1,
        image:WeatherApp,
        title:"Live Weather App",
        alt:"Live Weather App",
        desc:'Weather app showing live weather for given city.',
        github :'https://github.com/sahilgogave/WeatherApp',
        demo :'https://sahilgogave.github.io/WeatherApp/'
    },
    {
        id:1,
        image:Farewellcard,
        title:"Farewell Cards",
        alt:"Farewell Cards",
        desc:'A site made to bid farewell to capgemini',
        github :'https://github.com/sahilgogave/sahil-signingoff',
        demo :'https://sahil-signingoff.vercel.app/'
    },
    {
        id:3,
        image:MemoryGame,
        title:"Memory Game",
        alt:"Memory Game",
        desc:'Classic Memory game made using React.',
        github :'https://github.com/sahilgogave/logo-memory-game',
        demo :'https://logo-memory-game.onrender.com/'
    },
    // {
    //     id:5,
    //     image:StupidBlog,
    //     title:"Stupid Blog",
    //     alt:"Stupid Blog",
    //     desc:'Made using React blog connects to self made API in Node JS',
    //     github :'https://github.com/sahilgogave/StupidBlog',
    //     demo :'https://stupid-blog.onrender.com/'
    // },

    {
        id:4,
        image:TODO,
        title:"A To Do List",
        alt:"A To Do List",
        desc:'todo list app containing add,search and delete functionality',
        github :'https://github.com/sahilgogave/ToDoList',
        demo :'https://sahilgogave.github.io/ToDoList/'
    },
    {
        id:2,
        image:NoteIt,
        title:"Note-It",
        alt:"Note-It",
        desc:'Desktop note taking app made using React',
        github :'https://github.com/sahilgogave/Note-It',
        demo :'https://sahilgogave.github.io/Note-It/'
    },
    {
        id:9,
        image:Conference,
        title:"Conference",
        alt:"Conference",
        desc:'Conference app containing multiple pages',
        github :'https://github.com/sahilgogave/conference',
        demo :'https://sahilgogave.github.io/conference/'
    },

]

export default PortfolioData