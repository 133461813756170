import "./aboutme.css";
import Info from "./Info";
import CV from "../../assets/Sahil_Gogave_Resume.pdf";
import AboutImg from "../../assets/pic2.png";

const AboutMe = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My, Myself & I</span>

      <div className="about__container container grid">
        <img src={AboutImg} alt="SahilPic" className="about__img" />
        <div className="about__data">
          <Info />
          <p className="about__description">
            My name is Sahil and I have completed a Bachelor's degree in
            Computer Engineering from Pune University 
          </p>{" "}
          <p className="about__description">
            I'm quietly confident, naturally curious, and perpetually working on
            improving my chops one design problem at a time. In addition to my
            love of technology and design, I'm a fan of football, video games,
            TV series, and music. Oh! and I love dogs and cats.
          </p>
          <a 
          // download="Sahil_Gogave_Resume" href={CV} 
          href="https://drive.google.com/drive/folders/11Fr6vHX8hac5HPligS-bEF9hOKXIM3oW?usp=sharing" 
          className="button button--flex"
          target="_blank">
            Download CV
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z"
                fill="var(--container-color)"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
